import { Col } from 'react-bootstrap'

import './style.scss'
import PT from 'prop-types'

const LinkDetail = ({ abbr, links }) => {
  return (
    <Col md={5} className='linkDetail'>
      <div className='abbr'>{abbr}</div>
      <ul>
        {links.map((link, index) => (
          <li key={`${link.title}-${index}`}>
            <p>{link.title}</p>
            <div className='link'>
              <a href={`${link.url}`}>
                <p>{link.url}</p>
              </a>
            </div>
          </li>
        ))}
      </ul>
    </Col>
  )
}

LinkDetail.propTypes = {
  abbr: PT.string,
  links: PT.array
}

export default LinkDetail
