import React from 'react'
import './style.scss'

const PrivacyPolicy = () => (
  <div className='PrivacyPolicy'>
    <div className='PrivacyPolicy__content'>
      <h1>Privacy Policy</h1>
      <p><strong>Effective Date:</strong> 2022-07-01</p>
      <p><strong>Last Updated:</strong> 2024-07-01</p>

      <h2>Welcome to www.subter.io (the "Site"). Your privacy is paramount to us. This Privacy Policy outlines our commitment to protecting your privacy and describes the limited circumstances under which we may collect, use, and share information when you visit our Site.</h2>

      <h2>1. Information We Collect</h2>
      <p>We do not collect personal information about you when you visit our Site.</p>

      <h2>2. How We Use Your Information</h2>
      <p>Since we do not collect any personal information, we do not use your information for any purpose.</p>

      <h2>3. How We Share Your Information</h2>
      <p>We do not share, sell, or transfer any information about you to third parties.</p>

      <h2>4. Cookies and Tracking Technologies</h2>
      <p>We do not use cookies or any other tracking technologies to collect information about your activity on our Site.</p>

      <h2>5. Security</h2>
      <p>Although we do not collect personal information, we take the security of our Site seriously and use commercially reasonable measures to protect it from unauthorized access, use, alteration, or destruction.</p>

      <h2>6. International Data Transfers</h2>
      <p>Since we do not collect personal information, there are no international data transfers.</p>

      <h2>7. Children's Privacy</h2>
      <p>Our Site is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have inadvertently received personal information from a child under 13, we will take steps to delete such information.</p>

      <h2>8. Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our Site and updating the "Effective Date" at the top of this policy.</p>

      <h2>9. Contact Us</h2>
      <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
      <p>Email: tubers [at] subter.io</p>
    </div>
  </div>
)

export default PrivacyPolicy
