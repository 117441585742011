import 'bootstrap/dist/css/bootstrap.min.css'
import { Navbar, Nav } from 'react-bootstrap'
import { Link } from 'react-scroll'
import Logo from '../../assets/logo.svg'

import './style.scss'

const Menu = () => {
  return (
    <Navbar bg='transparent' expand='lg' variant='dark'>
      <img src={Logo} alt='Subter logo' />
      <Navbar.Toggle aria-controls='basic-navbar-nav' />
      <Navbar.Collapse id='basic-navbar-nav'>
        <Nav className='ml-auto'>
          <Link
            className='nav-link'
            to='what-it-is'
            spy
            hashSpy
            smooth
            duration={1000}
          >
            WHAT IT IS
          </Link>
          <Link
            className='nav-link'
            to='why-subter'
            spy
            hashSpy
            smooth
            duration={1000}
            offset={140}
          >
            WHY SUBTER?
          </Link>
          <Link
            className='nav-link'
            to='who-is-it-for'
            spy
            hashSpy
            smooth
            duration={1000}
            offset={40}
          >
            WHO IS IT FOR?
          </Link>
          <Link
            className='nav-link'
            to='how-it-works'
            spy
            hashSpy
            smooth
            duration={1000}
          >
            HOW IT WORKS
          </Link>
          <Link
            className='nav-link'
            to='data-privacy'
            spy
            hashSpy
            smooth
            duration={1000}
            offset={100}
          >
            DATA PRIVACY
          </Link>
          <a className='nav-link' href='https://subter.substack.com'>
            BLOG
          </a>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Menu
