import 'bootstrap/dist/css/bootstrap.min.css'
import { ImgComparisonSlider } from '@img-comparison-slider/react'
import SectionImageBefore from '../../assets/before.png'
import SectionImageAfter from '../../assets/after.png'
import HandleIcon from '../../assets/slider.png'

import './style.scss'

const WhatSection = () => {
  return (
    <section className='whatSection' id='what-it-is'>
      <div className='left'>
        <ImgComparisonSlider>
          <img
            className='before'
            slot='before'
            src={SectionImageBefore}
            alt=''
          />
          <img className='after' slot='after' src={SectionImageAfter} alt='' />
          <img
            slot='handle'
            width='20px'
            height='20px'
            className='custom-handle'
            src={HandleIcon}
            alt=''
          />
        </ImgComparisonSlider>
        <div className='caption'>slide to show or hide the secret message</div>
      </div>

      <div className='right'>
        <h1 className='title'>What it is?</h1>
        <div className='content'>
          <p>
            Subter is the app for secure communications. Using modern
            cryptography in conjunction with{' '}
            <a
              href='https://en.wikipedia.org/wiki/Steganography'
              className='text-blue'
            >
              steganography
            </a>{' '}
            Subter allows you to send messages to you recipients in{' '}
            <i>plain sight.</i>
          </p>
          <br />
          <p>
            Subter lets you send messages inside of your social media posts that
            only your friends can see. It allows you to operate securely on top
            of your existing social networks and not have to worry that your
            information is being mined, exploited, and mishandled.
          </p>
        </div>
      </div>
    </section>
  )
}

export default WhatSection
