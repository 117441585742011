import { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import MobileImage from '../../assets/mobile.png'
import './style.scss'

const INITIAL_FORM = {
  firstName: '',
  lastName: '',
  email: '',
  mobilePlatform: ''
}

const Hero = () => {
  const [showWaitlistModal, setShowWaitlistModal] = useState(false)
  const [formValues, setFormValues] = useState(INITIAL_FORM)
  const [formSubmitted, setFormSubmitted] = useState(false)

  const handleCloseWaitListModal = () => {
    setShowWaitlistModal(false)
    setFormValues(INITIAL_FORM)
    setFormSubmitted(false)
  }

  const handleShowWaitListModal = () => {
    setShowWaitlistModal(true)
  }

  const onChange = (value, field) => {
    setFormValues({
      ...formValues,
      [field]: value
    })
  }

  const submit = async () => {
    let empty = false

    Object.keys(formValues).forEach(k => {
      if (!formValues[k]) {
        empty = true
      }
    })

    if (empty) {
      window.alert('One or more requested details are not provided')
      return
    }

    await window.fetch(process.env.REACT_APP_WAITLIST_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      mode: 'no-cors',
      body: JSON.stringify(formValues)
    })

    setFormSubmitted(true)
  }

  return (
    <section className='hero'>
      <div className='left'>
        <h1 className='text'>
          Subter.
          <br />
          Like invisible ink for your images.
        </h1>
        <Button
          variant='dark'
          size='lg'
          className='callToAction'
          onClick={handleShowWaitListModal}
        >
          Try It Now
        </Button>
      </div>

      <div className='right'>
        <img src={MobileImage} alt='' />
      </div>

      <Modal show={showWaitlistModal} onHide={handleCloseWaitListModal}>
        <Modal.Header closeButton>Join Waiting List</Modal.Header>
        <Modal.Body>
          {
            !formSubmitted &&
              <Form>
                <Form.Group controlId='formBasicFirstName'>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type='text'
                    onChange={(evt) => onChange(evt.target.value, 'firstName')}
                  />
                </Form.Group>
                <Form.Group controlId='formBasicLastName'>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type='text'
                    onChange={(evt) => onChange(evt.target.value, 'lastName')}
                  />
                </Form.Group>
                <Form.Group controlId='formBasicEmail'>
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type='email'
                    onChange={(evt) => onChange(evt.target.value, 'email')}
                  />
                </Form.Group>
                <Form.Group controlId='formBasicMobilePlatform'>
                  <Form.Label>Mobile OS</Form.Label>
                  <Form.Check
                    type='radio'
                    name='mobilePlatform'
                    label='Android'
                    id='mobilePlatform-android'
                    onChange={() => onChange('android', 'mobilePlatform')}
                  />
                  <Form.Check
                    type='radio'
                    name='mobilePlatform'
                    label='iOS'
                    id='mobilePlatform-ios'
                    onChange={() => onChange('ios', 'mobilePlatform')}
                  />
                </Form.Group>
                <Button
                  variant='dark'
                  size='lg'
                  className='callToAction'
                  block
                  onClick={submit}
                >
                  Submit
                </Button>
              </Form>
          }
          {
            formSubmitted &&
              <p className='text-center'>Thank you for joining our waitlist!</p>
          }
        </Modal.Body>
      </Modal>
    </section>
  )
}

export default Hero
