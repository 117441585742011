import 'bootstrap/dist/css/bootstrap.min.css'
import HowItWorksImage from '../../assets/how-it-works.png'
import './style.scss'

const HowSection = () => {
  return (
    <section className='howSection' id='how-it-works'>
      <div className='left'>
        <h1>How it Works?</h1>
        <p>
          Subter works by encrypting your data before you send it to your
          recipient and then hiding that secret message inside of an image. This
          image can then be shared safely on your social networks, or elsewhere,
          to your recipient. In this way, you don't have to leave your network
          of connections behind as when you use a new app. You don't have to
          abandon all your connections in order to safely communicate as you
          would with other secure communications applications and you can still
          participate on those platforms.
        </p>
        <p>
          Subter also does not intermediate your messages like many platforms
          do. Your messages are encrypted on your device with your own set of
          cryptographic keys. The Subter service never handles your private keys
          and they remain on your device.{' '}
        </p>
      </div>
      <div className='right'>
        <img src={HowItWorksImage} alt='' />
      </div>
    </section>
  )
}

export default HowSection
