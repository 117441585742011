import 'bootstrap/dist/css/bootstrap.min.css'
import DataPrivacyImage1 from '../../assets/data-privacy-1.png'
import DataPrivacyImage2 from '../../assets/data-privacy-2.png'

import './style.scss'

const DataPrivacySection = () => {
  return (
    <section className='dataPrivacySection' id='data-privacy'>
      <h1 className='title'>Data Privacy</h1>

      <div className='content'>
        <div className='top'>
          <div className='topContent'>
            <p>
              Data privacy is not something that most people think about in
              their day to day lives. However, data privacy is increasingly
              important in today's hyper-connected, online and offline world. We
              tend to compartmentalize the things we do online and don't think
              that what happens there has a direct impact on what happens
              offline.{' '}
            </p>
            <p>
              This is not the case though. Our digital lives have become
              intrinsically linked to our physical lives through the usage of
              trackers, profiling, and data brokers. It used to be said that "on
              the internet, no one knows you're a dog". Now, it's not only known
              that you're a dog but what breed, what snacks you like, and how
              often you find that tennis ball. There are now innumerable
              examples of how we're being tracked across mediums and how that's
              actually impacting our daily lives.{' '}
            </p>
          </div>
          <div>
            <img className='image-2' src={DataPrivacyImage1} alt='' />
          </div>
        </div>
        <div className='bottom'>
          <div className='bottomContent'>
            <p>
              It's time we started to change this and defend ourselves from
              those that would treat our information as digital oil to pump.
              Much like all the other protections we put in place from locks on
              the doors, to wearing seat belts, to carrying insurance; We need
              to establish protections that we don't have to think about so that
              we can continue to enjoy our lives.{' '}
            </p>
            <p>
              But change is always hard and no one likes to learn entirely new
              things just to get the same results. That's why Subter offers the
              best of both worlds. A small, yet incremental change in how we
              communicate that will have longer lasting effects that protect us.
              If we could opt out our private information while still opting in
              to the network and its features, isn't that ideal?{' '}
            </p>
          </div>
          <img className='image-1' src={DataPrivacyImage2} alt='' />
        </div>
      </div>
    </section>
  )
}

export default DataPrivacySection
