import { Fragment } from 'react'
import { Row, Col } from 'react-bootstrap'

import LinkDetail from '../LinkDetail'
import './style.scss'

const Links = () => {
  const contents = [
    {
      abbr: '- Global Tracking Study',
      links: [
        {
          title: 'Ghostery Tracking Survey',
          url: 'https://www.ghostery.com/press/ghostery-global-tracking-study/'
        }
      ]
    },
    {
      abbr: '- Twitter',
      links: [
        {
          title: 'Twitter Breach',
          url: 'https://www.nbcnews.com/tech/security/twitter-breach-exposes-one-tech-s-biggest-threats-its-own-n1234076'
        }
      ]
    },
    {
      abbr: 'Lazybaer on Data Privacy',
      links: [
        {
          title: 'On Data Privacy',
          url: 'https://www.linkedin.com/pulse/data-privacy-c-w-d/?trackingId=IQl6sKlMTNKQwF0Ksw5a8Q%3D%3D'
        }
      ]
    },
    {
      abbr: '- Surveillance Capitalism',
      links: [
        {
          title: 'Cory Doctorow: How to Destroy Surveillance Capitalism',
          url: 'https://onezero.medium.com/how-to-destroy-surveillance-capitalism-8135e6744d59'
        }
      ]
    },
    {
      abbr: '- Facebook',
      links: [
        {
          title: 'FB Data Mining',
          url: 'https://www.datamation.com/big-data/facebook-and-data-mining-is-anything-private/'
        },
        {
          title: 'FB Data Breech',
          url: 'https://www.npr.org/2021/04/09/986005820/after-data-breach-exposes-530-million-facebook-says-it-will-not-notify-users'
        }
      ]
    }
  ]

  return (
    <section className='links' id='links'>
      <h1 className='title'>Links</h1>

      <Row>
        {contents.map((content, index) => (
          <Fragment key={index}>
            <LinkDetail
              abbr={content.abbr}
              links={content.links}
              key={content.abbr}
            />
            <Col md={1} key={index} />
          </Fragment>
        ))}
      </Row>
    </section>
  )
}

export default Links
