import './style.scss'

const Footer = () => {
  return (
    <div className='footer'>
      <p>
        &copy; 2024 Subter. All rights reserved.{' '}
        <span className='tnc'>
          Read our <a href='/privacy-policy'>Privacy Policy</a>
        </span>
      </p>
    </div>
  )
}

export default Footer
