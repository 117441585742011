import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/global.scss'
import './App.scss'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Menu from './components/Menu'
import Hero from './components/Hero'
import WhatSection from './components/WhatSection'
import WhySection from './components/WhySection'
import WhoSection from './components/WhoSection'
import HowSection from './components/HowSection'
import DataPrivacySection from './components/DataPrivacySection'
import Links from './components/Links'
import Footer from './components/Footer'
import PrivacyPolicy from './components/PrivacyPolicy'

const App = () => {
  return (
    <BrowserRouter>
      <div className='App'>
        <Routes>
          <Route
            path='/' element={
              <>
                <div className='overlay-big' />
                <div className='overlay-small' />
                <Menu />
                <Hero />
                <WhatSection />
                <WhySection />
                <WhoSection />
                <HowSection />
                <DataPrivacySection />
                <Links />
                <Footer />
              </>
        } />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </BrowserRouter>
  )
}

export default App
